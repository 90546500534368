<template lang="pug">
    authorized-layout
</template>

<script>
import AuthorizedLayout from '@/layouts/authorized-layout';

export default {
    name: "HomePage",
    components: {
        'authorized-layout': AuthorizedLayout,
    }
}
</script>